import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ProgressBar } from 'react-bootstrap';

// Components
import { PASSWORD_VALIDATION_TYPES } from 'schema/validation/password';

// Types
import { PasswordRulesProps } from 'types/password';

import './password-rules.style.scss';

const PasswordRules = ({ errors, password }: PasswordRulesProps) => {
    const violations = errors?.split(',');
    const passwordStrength = password ? 100 - (violations?.length ?? 0) * 20 : 0;
    const { t } = useTranslation();

    return (
        <div className="password">
            <ProgressBar className={`password-strength-${passwordStrength}`} now={passwordStrength} />
            <div className={`password-rules ${password ? 'touched' : ''}`}>
                <div className="password-rules-container">
                    <div
                        className={`password-rule ${
                            violations?.find(
                                (tkey) => tkey === PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey
                            )
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(
                            `registration.validationRules.${PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey}`
                        )}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find(
                                (tkey) => tkey === PASSWORD_VALIDATION_TYPES.CHARACTERS_IN_A_ROW.translationKey
                            )
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(
                            `registration.validationRules.${PASSWORD_VALIDATION_TYPES.CHARACTERS_IN_A_ROW.translationKey}`
                        )}
                    </div>
                    <div>{t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.AT_LEAST.translationKey}`)}</div>
                </div>
                <div className="password-rules-layout">
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_LOWERCASE.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_LOWERCASE.translationKey}`)}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_NUMBER.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_NUMBER.translationKey}`)}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_UPPERCASE.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_UPPERCASE.translationKey}`)}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_SYMBOL.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_SYMBOL.translationKey}`)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordRules;
