import * as yup from 'yup';

export const PASSWORD_VALIDATION_TYPES = {
    ONE_LOWERCASE: {
        translationKey: 'oneLowercase'
    },
    ONE_UPPERCASE: {
        translationKey: 'oneUppercase'
    },
    ONE_SYMBOL: {
        translationKey: 'oneSymbol'
    },
    ONE_NUMBER: {
        translationKey: 'oneNumber'
    },
    MIN_LENGTH_8_CHAR: {
        translationKey: 'minLength'
    },
    CONFIRMATION_MATCH: {
        translationKey: 'confirmationMatch'
    },
    CHARACTERS_IN_A_ROW: {
        translationKey: 'charactersInARow'
    },
    AT_LEAST: {
        translationKey: 'atLeastMessage'
    }
};
const PASSWORD_VALIDATION = [
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_LOWERCASE.translationKey });
            if (!password) return error;

            const regex = new RegExp(/[a-z]+/g);
            const matches = regex.exec(password);
            if (matches && matches.length > 0) return true;

            return error;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_UPPERCASE.translationKey });
            if (!password) return error;

            const regex = new RegExp(/[A-Z]+/g);
            const matches = regex.exec(password);
            if (matches && matches.length > 0) return true;

            return error;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_SYMBOL.translationKey });
            if (!password) return error;

            const regex = new RegExp(/[^a-zA-Z\d\s:]+/g);
            const matches = regex.exec(password);
            if (matches && matches.length > 0) return true;

            return error;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_NUMBER.translationKey });
            if (!password) return error;

            const regex = new RegExp(/[0-9]+/g);
            const matches = regex.exec(password);
            if (matches && matches.length > 0) return true;

            return error;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey });
            if (!password) return error;

            if (password.length >= 8) return true;

            return error;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.CHARACTERS_IN_A_ROW.translationKey });
            if (!password) return error;
            // Validates there are no more than 2 identical characters in a row
            const hasRepeatedChars = password.split('').some((v, i, a) => {
                if (i <= 1) return false;
                return a[i - 1] === a[i] && a[i - 2] === a[i - 1];
            });

            if (!hasRepeatedChars) return true;
            return error;
        }
    }
];

//  runs through each password validation step and produces a validation error if one was returned from the validator
export const validatePassword = (
    password: string | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    if (!password) {
        return true;
    }
    const { createError } = context;

    //  maps the validators results or returns null which will be filtered out
    const errors = PASSWORD_VALIDATION.map((validator) => {
        const result = validator.validate(password, context);
        if (result && (result as yup.ValidationError).message) return (result as yup.ValidationError).message;
        return null;
    }).filter(Boolean); //  removes null results

    if (errors.length === 0) return true;

    //  joins all valdation messages to be used by a consumer, i.e. PasswordRules
    return createError({ message: errors.join(',') });
};

//  validates that both passwords match
export const validatePasswordConfirm = (
    passwordConfirm: string | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    const { createError, parent } = context;
    const { password } = parent;
    const error = createError({ message: PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey });

    if (!passwordConfirm || passwordConfirm !== password) return error;

    return true;
};

//  validates that both passwords match when updating password within profile
export const validateNewPasswordConfirm = (
    newPasswordConfirm: string | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    const { createError, parent } = context;
    const { newPassword } = parent;
    const error = createError({ message: PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey });

    if (!newPasswordConfirm || newPasswordConfirm !== newPassword) return error;

    return true;
};
